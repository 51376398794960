const actionTypes = {
  USER: 'USER',
  PROFILE: 'PROFILE',
  LASTPATH: 'LASTPATH',
  SNIPPETS: 'SNIPPETS',
  SELECTEDSNIPPET: 'SELECTEDSNIPPET',
  FEEDBACK: 'FEEDBACK',
  REQUESTEDROUTE: 'REQUESTEDROUTE',
  CHANGEACTIVEROUTE: 'CHANGEACTIVEROUTE'
};

export default actionTypes;
