import React from 'react';

import './TermsOfService.scss';

const TermsOfService = props => {
  return (
    <div className='page-terms-of-service'>
      <div className='head'>
        <h1>TERMS & CONDITIONS</h1>
      </div>
      <div className='body'>
        <p>
          Thanks for using EyeMail.
          <br />
          <br />
          Please read these Terms carefully. By using EyeMail or signing up for
          an account, you’re agreeing to these Terms, which will result in a
          legal agreement between you and EyeMail (“Agreement”). We’ll start
          with the basics, including a few definitions that should help you
          understand these Terms. EyeMail (“EyeMail,” “we,” or “us”) is an
          online marketing platform (the “App”) that allows you to create
          campaigns, generate snippets to manage certain marketing campaigns,
          including, without limitation, emails, advertisements, and mailings
          (each a “Campaign,” and collectively, “Campaigns”), among other
          things. The App is offered through our websites, including 
          <a target='_blank' href='https://mailchimp.com/'>
            http://www.EyeMail.com
          </a>
          ,and any other website or mobile application owned, operated or
          controlled by us (we’ll collectively refer to these as the “Website,”
          and together with the App, the “Service”). EyeMail is a Georgia
          limited liability company whose legal name is EyeMail Inc. EyeMail has
          employees, independent contractors, and representatives (“our Team”).
          As a customer of the Service or a representative of an entity that’s a
          customer of the Service, you’re a “Member” according to this Agreement
          (or “you”).
        </p>
        <p>
          These Standard Terms of Use define the terms and conditions under
          which you’re allowed to use the Service in accordance with the
          Agreement, and how we’ll treat your account while you’re a Member. If
          you don’t agree to these Terms, you must immediately discontinue your
          use of the Service.
        </p>
        <p>
          Some features of the Service are offered as add-ons to your EyeMail
          account (“Add- ons”). Unless expressly stated otherwise, these Terms
          apply to the use of Add-ons. Some Add-ons are intended for particular
          use cases and may also have additional terms or restrictions
          (“Additional Terms”). Additional Terms for Add-ons can be found on the
          Website, and Members agree to the applicable Additional Terms at the
          time they choose to add the corresponding Add-on. Also note that a
          Member’s account may have access to unique features of the Service
          based on their historic usage or status.
        </p>
        <p>
          When you download the App through the Apple App Store, Google Play or
          other app store or distribution platform (“App Provider”), you
          acknowledge and agree that: (i) these Terms are between us, and not
          with the App Provider, and that we are responsible for the Service,
          not the App Provider; (ii) the App Provider has no obligation to
          furnish maintenance and support services or handle any warranty
          claims; (iii) the App Provider is not responsible for addressing any
          claims you or any third party have relating to the App; and (iv) the
          App Provider is a third party beneficiary of these Terms as related to
          your use of the App, and the App Provider will have the right to
          enforce these Terms as related to your use of the App against you.
        </p>
        <p>
          If you have any questions about our Terms, feel free to contact us,
          hello@eyemailinc.com
        </p>
        <h2>Account</h2>
        <h3>1. Eligibility</h3>
        <p>In order to use the Service, you must:</p>
        <ol>
          <li>
            be at least eighteen (18) years old and able to enter into
            contracts;
          </li>
          <li>complete the account registration process;</li>
          <li>agree to these Terms;</li>
          <li>
            provide true, complete, and up-to-date contact and billing
            information;
          </li>
          <li>
            not be based in Cuba, Iran, North Korea, Syria, or any other country
            that is subject to a U.S. government embargo, or that has been
            designated by the U.S. government as a “terrorist-supporting”
            country; and
          </li>
          <li>
            not be listed on any U.S. government list of prohibited or
            restricted persons.
          </li>
        </ol>
        <p>
          By using the Service, you represent and warrant that you meet all the
          requirements listed above, and that you won’t use the Service in a way
          that violates any laws or regulations. Note that by representing and
          warranting, you are making a legally enforceable promise.
          <br />
          <br />
          EyeMail may refuse service, close accounts of any Members, and change
          eligibility requirements at any time.
        </p>

        <h3>2. Term</h3>
        <p>
          When you sign up for an account and agree to these Terms, the
          Agreement between you and EyeMail is formed, and the term of the
          Agreement (the “Term”) will begin. The Term will continue for as long
          as you have an EyeMail account or until you or we terminate the
          Agreement in accordance with these Terms, whichever happens first. If
          you sign up for an account on behalf of a company or other entity, you
          represent and warrant that you have the authority to accept these
          Terms and enter into the Agreement on its behalf.
        </p>
        <h3>3. Closing Your Accoun</h3>
        <p>
          You or EyeMail may terminate the Agreement at any time and for any
          reason by terminating your EyeMail account or giving notice to the
          other party. We may suspend the Service to you at any time, with or
          without cause. If we terminate your account without cause, and your
          account is a paid account, we’ll refund a prorated portion of your
          monthly prepayment for a Monthly Plan or reimburse you for unused Pay
          as You Go Credits. We won’t refund or reimburse you in any other
          situation, including if your account is suspended or terminated for
          cause, like a breach or any violation of the Agreement. If your
          account is inactive for 24 or more months, we may terminate the
          account. Once your account is terminated, you acknowledge and agree
          that we may permanently delete your account and all the data
          associated with it, including your Campaigns. Usernames are unique and
          can only be used once. If your account has been terminated, the
          username will no longer be available for use on any future accounts
          and cannot be reclaimed.
        </p>
        <h3>4. Changes</h3>
        <p>
          We may change any of the Terms by posting revised Terms on our
          Website. Unless you terminate your account, the new Terms will be
          effective immediately and apply to any continued or new use of the
          Service. We may change the Service, Add-ons, or any features of the
          Service at any time, and we may discontinue the Service, Add-ons, or
          any features of the Service at any time.
        </p>
        <h3>5. Account and Password</h3>
        <p>
          You’re responsible for keeping your account name and password
          confidential. You’re also responsible for any account that you have
          access to and any activity occurring in such account (other than
          activity that EyeMail is directly responsible for that isn’t performed
          in accordance with your instructions), whether or not you authorized
          that activity. You’ll immediately notify us of any unauthorized access
          or use of your accounts. We’re not responsible for any losses due to
          stolen or hacked passwords. We don’t have access to your current
          password, and for security reasons, we may only provide you with
          instructions on how to reset your password. We have the right to
          update any of your contact information in your account for billing
          purposes. In addition, you represent and warrant that all information
          you provide to us when you establish an account, and when you access
          and use the Service, is and will remain complete and accurate.
        </p>
        <h3>6. Account Disputes</h3>
        <p>
          We don’t know the inner workings of your organization or the nature of
          your personal relationships. You won’t request access to or
          information about an account that’s not yours, and you’ll resolve any
          account-related disputes directly with the other party. We decide who
          owns an account based on the content in that account, and if multiple
          people or entities are identified in the content, then we’ll rely on
          the contact and profile information listed for that account. In cases
          where differing contact and profile information is present, we’ll
          require you to resolve the matter through proper channels outside of
          EyeMail.
        </p>
        <p>
          When a dispute is identified, we may suspend any account associated
          with the dispute, including disabling login and sending capabilities,
          to protect the security and privacy of the data held within the
          account.
        </p>
        <h2>Payment</h2>
        <h3>7. Paid Monthly Plans and Audience</h3>
        <p>
          If you had a paid Monthly Plan on or before December 15, 2019, the
          following Section 7 may not apply to you and your account. If it does
          not apply, an alternate version of Section 7 applicable to you and
          your account is available in your account (navigate to “Account,” then
          “Billing,” then “Monthly plans or credits”).
        </p>
        <h3>7A. Paid Monthly Plans</h3>
        <p>
          When you sign up for a paid monthly plan, you are required to
          self-select both your projected audience, as described in Section 7B
          below, and your package (e.g., Advanced, Premium) (together, your
          “Monthly Plan”) from those posted on our Website based on your
          anticipated use of the Service. Each Monthly Plan offers different
          pricing and feature options. Once you select your Monthly Plan,
          EyeMail will never automatically upgrade or downgrade your Monthly
          Plan. So, give yourself room to grow if you’ll need it! If you exceed
          your Monthly Plan usage limits (either by exceeding your audience or
          monthly email limits, or both), you will incur additional charges for
          the prior billing cycle based on your current Monthly Plan, which will
          be detailed on your bill and in your account. Before paying for your
          Monthly Plan for the first time, you will have an opportunity to
          preview the additional charges which would apply to your Monthly Plan
          should you incur them by exceeding your self-selected usage limits. If
          you decide to proceed, and you incur additional charges, you agree to
          pay them on or before the next Pay Date, as described below. You will
          always have the option to upgrade to a higher tier at any time. If you
          upgrade during a billing cycle, you may elect to upgrade your package,
          your audience limit, or both. If you elect to upgrade both, the
          upgrade amount for the package upgrade is calculated first, and the
          upgrade amount for the audience limit upgrade is calculated second. In
          any event, your monthly charge for that billing cycle will be
          calculated based on the number of days in that billing cycle during
          which you were at each of the lower tier and the higher tier
          (including any applicable additional charges for the higher tier). You
          will also have the option to downgrade to a lower tier at any time,
          but if you elect to downgrade during a billing cycle, your downgrade
          will not be effective until the end of that billing cycle, and you
          will still be charged for that entire billing cycle at the higher tier
          (including any applicable additional charges for the higher tier).
        </p>
        <p>
          When you sign up for a Monthly Plan, you agree to monthly recurring
          billing, starting on the date you sign up. Billing occurs on the same
          day each month, based on the date that you started your Monthly Plan.
          Payments are due for any month on the same or closest date to the day
          you made your first monthly payment (the “Pay Date”). If any part of a
          month is included in the Term, then payment is required for the full
          month. If you exceed your self-selected usage limits for your Monthly
          Plan, then you’ll have to pay additional charges (as described above)
          for the prior billing cycle on or before the next Pay Date, even if
          the Term ends or you downgrade to a Free Plan before that payment is
          otherwise due. Billing for Monthly Plans may be paused, and you may
          choose to close your account permanently at any time.
        </p>
        <h3>7B. Audience and Archiving</h3>
        <h3>9. Pay as You Go Credits</h3>
        <p>
          You may also buy email credits to use the Service (“Pay as You Go
          Credits”), as explained on our Website. You will have an opportunity
          to review current rates for Pay as You Go Credits in your account
          prior to purchase. If you choose to buy Pay as You Go Credits, you’ll
          have access to the features of the Service included in the Essentials
          package, and other than the monthly payment requirement, all of these
          Terms will still apply to you. Pay as You Go Credits purchased on or
          after Dec 15, 2019 roll over each month and expire 12 months after
          purchase. Pay as You Go Credits have no cash value, cannot be refunded
          or redeemed for cash, and represent a limited license to use the
          Service for the specified volume and type of service.
        </p>
        <h3>10. Debit and Credit Cards</h3>
        <p>
          As long as you’re a Member with a Monthly Plan or otherwise have an
          outstanding balance with us, you’ll provide us with valid debit or
          credit card (“card”) information and authorize us to deduct your
          charges against that card. You’ll replace the information for any card
          that expires with information for a valid one. If your card is
          automatically replaced with a new card by a payment processor, you
          acknowledge and agree that we’re authorized to deduct any charges on
          your account against the new card. Anyone using a card represents and
          warrants that they are authorized to use that card, and that any and
          all charges may be billed to that card and won’t be rejected. If we’re
          unable to process your card order, we’ll try to contact you by email
          and suspend your account until your payment can be processed.
        </p>
        <h3>11. Refunds</h3>
        <p>
          We’ll give you a refund for a prepaid month or reimburse you for
          unused Pay as You Go Credits if we stop providing the Service and
          terminate your account without cause. You won’t be entitled to a
          refund or credit from us under any other circumstances. We may, at our
          sole discretion, offer a refund, discount or credit.
        </p>
        <h3>12. Charges for Add-Ons</h3>
        <p>
          If you use an Add-on that has a charge, then you’ll be billed that
          additional amount with each billing cycle for as long as the Add-on is
          active. Your billing cycle for an Add-on may differ from your billing
          cycle for your Monthly Plan or other features of the Service, and
          certain Add-ons may require upfront payment for their entire billing
          cycle. If you use Mandrill to send bulk emails in violation of our
          Acceptable Use Policy, then we may charge you at the comparable
          EyeMail plan or terminate your account.
        </p>
        <h3>13. Billing Changes</h3>
        <p>
          We may change our fees, including our charges for Monthly Plans and
          Pay as You Go Credits, at any time by posting a new pricing structure
          to our Website or in your account and/or sending you a notification by
          email. Quoted fees don’t include sales or other transaction-based
          taxes of any kind.
        </p>
        <h2>Rights</h2>
        <h3>14. Proprietary Rights Owned by Us and by You</h3>
        <p>
          We own all proprietary rights in the Service, including, but not
          limited to, patents, trademarks, service marks, trade secrets,
          copyrights, and other intellectual property rights. You will respect
          our proprietary rights in the Service, and you may only use our brand
          assets according to our brand guidelines. You represent and warrant
          that you either own or have permission to use all of the material,
          content, data, and information (including your personal information
          and the personal information of others) you submit to EyeMail in the
          course of using the Service (“Content”). You retain ownership of the
          Content that you upload to the Service. We may use or disclose your
          Content (including any personal information therein) only as described
          in these Terms, our Privacy Policy and Cookie Statement.
        </p>
        <h3>15. Privacy Policy</h3>
        <p>
          Your privacy is important to us. Please read our Privacy Policy and
          Cookies Statement for information regarding how we collect, use, and
          disclose your Content and personal information and protect your
          privacy when you use the Service.
        </p>
        <h3>16. Right to Review Content and Campaigns</h3>
        <p>
          We may view, copy, and internally distribute Content from your
          Campaigns and account to create algorithms and programs (“Tools”) that
          help us spot problem accounts and improve the Service. We use these
          Tools to find Members who violate these Terms or laws and to study
          data internally to make the Service smarter and create better
          experiences for Members and their contacts.
        </p>
        <h2>Rules and Abuse</h2>
        <h3>17. General Rules</h3>
        <p>By agreeing to these Terms, you promise to follow these rules:</p>
        <ol>
          <li>You won’t send spam!</li>
          <li>
            You won’t use purchased, rented, or third-party lists of email
            addresses.
          </li>
        </ol>
        <p>
          EyeMail doesn’t allow accounts that promote or incite harm toward
          others or that promote discriminatory, hateful, or harassing Content.
          To this end, we may suspend or terminate your account if you send a
          Campaign or otherwise distribute any Content that we determine, in our
          sole discretion, contains either of the following:
        </p>
        <ul>
          <li>
            A Threat of Physical Harm. This means any statement, photograph,
            advertisement, or other Content that in our sole judgment could be
            reasonably perceived to threaten, advocate, or incite physical harm
            to or violence against others.
          </li>
          <li>
            Hateful Content. This means any statement, image, photograph,
            advertisement, or other Content that in our sole judgment could be
            reasonably perceived to harm, threaten, promote the harassment of,
            promote the intimidation of, promote the abuse of, or promote
            discrimination against others based solely on race, ethnicity,
            national origin, sexual orientation, gender, gender identity,
            religious affiliation, age, disability, disease, or immigration
            status.
          </li>
        </ul>
        <p>
          We also may suspend or terminate your account if we determine, in our
          sole discretion, that you are either:
        </p>
        <ul>
          <li>
            an organization that has publicly stated or acknowledged that its
            goals, objectives, positions, or founding tenets include statements
            or principles that could be reasonably perceived to advocate,
            encourage, or sponsor Hateful Content or A Threat of Physical Harm;
          </li>
          <li>
            a person that has publicly made a comment or statement, or otherwise
            publicly made known a position, including by membership in an
            organization as discussed above, that could be reasonably perceived
            as Hateful Content or A Threat of Physical Harm; or
          </li>
          <li>
            a person or organization that has acted in such a way as could be
            reasonably perceived to support, condone, encourage, or represent
            Hateful Content or A Threat of Physical Harm.
          </li>
        </ul>
        <p>
          If you violate any of these rules, then we may suspend or terminate
          your account.
        </p>
        <h3>18. Reporting Abuse</h3>
        <p>
          If you think anyone is violating any of these Terms, please notify us
          immediately. If you received spam you think came from an EyeMail
          Member, please report it to our team. (Every EyeMail or Campaign sent
          through the Service has an embedded campaign tracking ID in the email
          header, making it easy to report suspected spam.) If you think anyone
          has posted material that violates any protected marks or copyrights,
          then you can notify us.
        </p>
        <h3>19. Bandwidth Abuse/Throttling</h3>
        <p>
          You may only use our bandwidth for your Content and Campaigns. We
          provide image and data hosting only for your use of the Service, so
          you may not host images on our servers for anything else. We may
          throttle your sending or connection through our API at our discretion.
        </p>
        <h3>20. Compliance with Laws</h3>
        <p>
          You represent and warrant that your use of the Service will comply
          with all applicable laws and regulations. You’re responsible for
          determining whether the Service is suitable for you to use in light of
          your obligations under any regulations like HIPAA, GLB, EU data
          privacy laws (including the General Data Protection Regulation) (“EU
          Data Privacy Laws”), United States export control laws and regulations
          and economic sanctions laws and regulations (“U.S. Export Control Laws
          and Regulations”), or other applicable laws. If you’re subject to
          regulations (like HIPAA) and you use the Service, then we won’t be
          liable if the Service doesn’t meet those requirements. You may not use
          the Service for any unlawful or discriminatory activities, including
          acts prohibited by the&nbsp;
          <a
            target='_blank'
            href='https://www.ftc.gov/enforcement/statutes/federal-trade-commission-act'>
            Federal Trade Commission Act
          </a>
          ,
          <a
            target='_blank'
            href='https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/fair-credit-reporting-act'>
            Fair Credit Reporting Act
          </a>
          ,
          <a
            target='_blank'
            href='https://www.justice.gov/crt/equal-credit-opportunity-act-3'>
            Equal Credit Opportunity Act
          </a>
          ,
          <a
            target='_blank'
            href='https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule'>
            Children’s Online Privacy Protection Act
          </a>
          , or any other applicable laws.
        </p>
        <p>
          If you collect any personal information pertaining to a minor and
          store such information within your EyeMail account, you represent and
          warrant that you have obtained valid consent for such activities
          according to the applicable laws of the jurisdiction in which the
          minor lives.
        </p>
        <p>
          If you’re located in the European Economic Area, the United Kingdom,
          or Switzerland (collectively, the “EEA”), and/or distribute Campaigns
          or other Content through the Service to, and/or otherwise collect
          information through the Service from, anyone located in those
          countries (each such Member an “EEA Member”), you agree, represent and
          warrant (as applicable) to EyeMail that:
        </p>
        <ol>
          <li>
            You will clearly post, maintain, and abide by a publicly accessible
            privacy notice on the digital properties from which the underlying
            data is collected that (a) satisfies the requirements of applicable
            data protection laws, (b) describes your use of the Service, and (c)
            includes a link to EyeMail’s Privacy Policy.
          </li>
          <li>
            You will get and maintain all necessary permissions and valid
            consents required to lawfully transfer data to EyeMail and to enable
            such data to be lawfully collected, processed, and shared by EyeMail
            for the purposes of providing the Service or as otherwise directed
            by you.
          </li>
          <li>
            You will comply with all laws and regulations applicable to the
            Campaigns sent through the Service, including those relating to (a)
            acquiring consents (where required) to lawfully send Campaigns, (b)
            the Content of Campaigns, and (c) your Campaign deployment
            practices.
          </li>
          <li>
            You will provide and obtain all notices and obtain all necessary
            consents required by applicable data protection laws to enable
            EyeMail to deploy cookies and similar tracking technologies (like
            web beacons or pixels) lawfully on and collect data from the devices
            of contacts and end users.
          </li>
        </ol>
        <p>
          You agree to indemnify and hold us harmless from any losses, including
          all legal fees and expenses, that result from your breach of this
          Section 20.
        </p>
        <h3>21. Export Controls</h3>
        <p>
          The software that supports the App (the “Software”) is subject to U.S.
          Export Control Laws and Regulations. Export laws are set up by the
          government to keep certain goods and services from reaching other
          countries, usually because of security concerns or trade agreements.
          None of the Software may be downloaded or otherwise exported or
          re-exported in violation of U.S. Export Control Laws and Regulations
          and any other applicable export laws and regulations (collectively,
          “Export Control Laws”). Therefore, you agree that you won’t, directly
          or indirectly through a third party, allow the Software or your
          Campaigns to be accessed or generated from within, or distributed or
          sent to, any prohibited or embargoed country as mentioned in any
          Export Control Laws. In addition, you certify that neither you nor any
          principals, officers, directors, or any person or entity you know to
          be directly involved with the use of the Service is designated on any
          U.S. government list of prohibited or restricted persons.
        </p>
        <p>
          It’s important to note that this Section 21 isn’t meant to provide a
          comprehensive summary of the Export Control Laws that govern EyeMail,
          the Service, or the Software. You’re downloading and using the
          Software at your own risk, and it’s your responsibility to consult
          with a legal advisor to make sure your use of the Service and the
          Software complies with applicable laws.
        </p>
        <h2>Liability</h2>
        <h3>22. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, you acknowledge and agree that
          (i) you assume full responsibility for any loss that results from your
          use of the Service, including any downloads from the Website; (ii) we
          and our Team won’t be liable for any indirect, punitive, special, or
          consequential damages under any circumstances, even if they’re based
          on negligence or we’ve been advised of the possibility of those
          damages; and (iii) in any calendar month, our total liability to you
          arising under or in connection with the Agreement—whether in contract,
          tort (including negligence), breach of statutory duty, or
          otherwise—will be no more than what you paid us for the Service the
          preceding month.
        </p>
        <p>
          For the avoidance of doubt, in no instance will we or our Team be
          liable for any losses or damages you suffer if you use the Service in
          violation of these Terms, regardless of whether we terminate or
          suspend your account due to such violation.
        </p>
        <h3>23. No Warranties</h3>
        <p>
          To the maximum extent permitted by law, we provide the Service as-is.
          This means that, except as expressly stated in these Terms, we don’t
          provide warranties, conditions, or undertakings of any kind in
          relation to the Service, either express or implied. This includes, but
          isn’t limited to, warranties of merchantability and fitness for a
          particular purpose, which are, to the fullest extent permitted by law,
          excluded from the Agreement. Since Members use the Service for a
          variety of reasons, we can’t guarantee that it’ll meet your specific
          needs.
        </p>
        <h3>24. Indemnity</h3>
        <p>
          You agree to indemnify and hold us and our Team harmless from any
          losses, including legal fees and expenses that directly or indirectly
          result from any claims you make that aren’t allowed under these Terms
          due to a “Limitation of Liability” or other provision. (Indemnity is
          an agreement to compensate someone for a loss.) You also agree to
          indemnify and hold us harmless from any losses, including legal fees
          and expenses, that directly or indirectly result from (i) your
          Content, (ii) your use of the Service, (iii) your violation of any
          laws or regulations, (iv) third-party claims that you or someone using
          your password did something that, if true, would violate any of these
          Terms, (v) any misrepresentations made by you, or (vi) a breach of any
          representations or warranties you’ve made to us.
        </p>
        <h3>25. Equitable Relief</h3>
        <p>
          Your violation of these Terms may cause irreparable harm to us and our
          Team. Therefore, we have the right to seek injunctive relief or other
          equitable relief if you violate these Terms (meaning we may request a
          court order to stop you).
        </p>
        <h3>26. Subpoena Fees</h3>
        <p>
          If we have to provide information in response to a subpoena, court
          order, or other legal, governmental, or regulatory inquiry related to
          your account, then we may charge you for our costs. These fees may
          include attorney and employee time spent retrieving the records,
          preparing documents, and participating in a deposition.
        </p>
        <h3>27. Disclaimers</h3>
        <p>
          We and our Team aren’t responsible for the behavior of any third
          parties, linked websites, or other Members.
        </p>
        <h2>Other Important Items</h2>
        <h3>28. Notice to U.S. Government End Users</h3>
        <p>
          The Software and Website, including all documentation, are “Commercial
          Items,” as that term is defined at 48 C.F.R. §2.101, and consist of
          “Commercial Computer Software” and “Commercial Computer Software
          Documentation.” The Commercial Computer Software and Commercial
          Computer Software Documentation are licensed to U.S. government end
          users:
        </p>
        <ol>
          <li>only as Commercial Items,</li>
          <li>with the same rights as all other end users, and</li>
          <li>according to the Terms.</li>
        </ol>
        <p>
          Published and unpublished rights are reserved under the copyright laws
          of the United States. The manufacturer is EyeMail inc., 3423 Piedmont
          Road, Atlanta, Georgia 30305.
        </p>
        <h3>29. Assignments</h3>
        <p>
          You may not assign any of your rights under this Agreement to anyone
          else. We may assign our rights to any other individual or entity at
          our discretion.
        </p>
        <h3>30. Choice of Law</h3>
        <p>
          The State of Georgia’s laws, except for conflict of laws rules, will
          apply to any dispute related to the Agreement or the Service. You
          acknowledge and agree that any dispute related to the Agreement or the
          Service itself will be decided by the state or federal courts in
          Fulton County, Georgia, and each party consents to personal
          jurisdiction in those courts.
        </p>
        <h3>31. Force Majeure</h3>
        <p>
          We won’t be held liable for any delays or failure in performance of
          any part of the Service, from any cause beyond our control. This
          includes, but is not limited to, acts of god, changes to law or
          regulations, embargoes, war, terrorist acts, riots, fires,
          earthquakes, nuclear accidents, zombie apocalypse, floods, strikes,
          power blackouts, volcanic action, unusually severe weather conditions,
          and acts of hackers, or third-party internet service providers.
        </p>
        <h3>32. Survivability</h3>
        <p>
          Even if this Agreement is terminated, the following sections will
          continue to apply: Proprietary Rights Owned by Us and by You,
          Compliance with Laws, Limitation of Liability, No Warranties,
          Indemnity, Choice of Law, Severability, and Entire Agreement.
        </p>
        <h3>33. Severability</h3>
        <p>
          If it turns out that a section of these Terms isn’t enforceable, then
          that section will be removed or edited as little as required, and the
          rest of the Agreement will still be valid.
        </p>
        <h3>34. Interpretation</h3>
        <p>
          The headers and sidebar text are provided only to make these Terms
          easier to read and understand. The fact that we wrote these Terms
          won’t affect the way the Agreement is interpreted.
        </p>
        <h3>35. Waiver</h3>
        <p>
          If we don’t immediately take action on a violation of these Terms,
          we’re not giving up any rights under the Terms, and we may still take
          action at some point.
        </p>
        <h3>36. No Changes in Terms at Request of Member</h3>
        <p>
          Because we have so many Members, we can’t change these Terms for any
          one Member or group.
        </p>
        <h3>37. Further Actions</h3>
        <p>
          You’ll provide all documents and take any actions necessary to meet
          your obligations under these Terms.
        </p>
        <h3>38. Notification of Security Incident</h3>
        <p>
          If we become aware of a security incident related to our systems or
          databases that contain personal information of you or your contacts,
          we’ll notify you if required by law. In that event, we’ll also provide
          you with information about that incident so that you can evaluate the
          consequences to you and any legal or regulatory requirements that may
          apply to you, unless we’re prevented from doing so by legal, security
          or confidentiality obligations. Notifying you of a security incident
          or cooperating with you to respond to one will not be deemed an
          acknowledgement or assumption of any liability or fault of EyeMail for
          such incident.
        </p>
        <h3>39. Notices</h3>
        <p>
          Any notice to you will be effective when we send it to the last email
          or physical address you gave us or when posted on our Website. Any
          notice to us will be effective when delivered to us along with a copy
          to our legal counsel: Attn. Legal Department, EyeMail, 675 Ponce de
          Leon Ave NE, Suite 5000, Atlanta, Georgia, 30308, or any addresses as
          we may later post on the Website.
        </p>
        <h3>40. Entire Agreement</h3>
        <p>
          These Terms you’ve agreed to by enabling any Add-ons make up the
          entire agreement between us in relation to its subject matter and
          supersede all prior agreements, representations, and understandings.
          Any Additional Terms will be considered incorporated into the
          Agreement when you activate the corresponding Add-on.
        </p>
        <p>
          Where there’s a conflict between these Terms and the Additional Terms,
          the Additional Terms will control to the extent of the conflict.
        </p>
        <p>Thanks for taking the time to learn about EyeMail’s policies.</p>
      </div>
    </div>
  );
};

export { TermsOfService };
