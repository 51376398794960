const modules = {
  home: { name: "Home", url: "/", isHome: true },
  campaign: { name: "Campaigns", url: "/campaigns" },
  eyemail: { name: "EyeMails", url: "/eyemails" },
  livePreview: { name: "EyeMails", url: "/livePreview" },
  live: { name: "EyeMails", url: "/live" },
  livePreviewEmail: { name: "EyeMails", url: "/emailpreview" },
  livePreviewEmailData: { name: "EyeMails", url: "/eyemail" },
  report: { name: "Reports", url: "/reports" },
  template: { name: "Templates", url: "/templates" },
  company: { name: "Companies", url: "/companies" },
  video: { name: "Video Gallery", url: "/video-library" },
  settings: { name: "Settings", url: "/settings" },
  support: { name: "Support Center", url: "/support" },
  productLines: { name: "Product Lines", url: "/product-lines" },
  feeds:{name: "EyeMails", url: "/feeds"},
};

const site = {
  modules: modules,
  routes: {
    home: {
      exact: true,
      module: modules.home,
      path: "/",
      title: "Home",
      isIndex: true,
    },
    productLines: {
      exact: true,
      module: modules.productLines,
      path: "/product-lines",
      title: "Product Lines",
      isIndex: true,
    },
    settings: {
      exact: true,
      module: modules.settings,
      path: "/settings",
      title: "Settings",
      isIndex: true,
    },
    createSnippet: {
      exact: true,
      module: modules.eyemail,
      path: "/create-eyemail",
      title: "New EyeMail",
    },
    mySnippets: {
      exact: true,
      module: modules.eyemail,
      path: "/eyemails",
      title: "My EyeMails",
      isIndex: true,
    },
    viewSnippet: {
      exact: true,
      module: modules.eyemail,
      route: "/eyemails",
      path: "/eyemails/:id",
      title: "View Detail",
    },
    viewLiveSnippet: {
      exact: true,
      module: modules.livePreview,
      route: "/livePreview",
      path: "/livePreview/:id",
      title: "View Detail",
      requireAuth: false,
    },
    live: {
      exact: true,
      module: modules.live,
      route: "/live",
      path: "/live/:id",
      title: "View Detail",
      requireAuth: false,
    },
    livePreviewEmail: {
      exact: true,
      module: modules.livePreviewEmail,
      route: "/emailpreview",
      path: "/emailpreview/:id",
      title: "View Detail",
      requireAuth: false,
    },
    livePreviewEmailData: {
      exact: true,
      module: modules.livePreviewEmailData,
      route: "/eyemail",
      path: "/eyemail/:id",
      title: "View Detail",
      requireAuth: false,
    },
    editSnippet: {
      exact: true,
      module: modules.eyemail,
      path: "/update-eyemail/:id",
      route: "/update-eyemail",
      title: "Update EyeMail",
    },
    videoLibrary: {
      exact: true,
      module: modules.video,
      path: "/video-library",
      title: "My Videos",
      isIndex: true,
    },
    emailVerification: {
      exact: true,
      module: modules.home,
      path: "/emailverification",
      title: "Account confirmation",
      requireAuth: false,
      isHidden: true,
    },
    forgotPassword: {
      exact: true,
      module: modules.home,
      path: "/forgotpassword",
      title: "Forgot Password",
      requireAuth: false,
      isHidden: true,
      container: true,
    },
    resetPassword: {
      exact: true,
      module: modules.home,
      path: "/resetpassword",
      title: "Reset Password",
      requireAuth: false,
      isHidden: true,
      container: true,
    },
    signIn: {
      exact: true,
      module: modules.home,
      path: "/signin",
      title: "Login",
      requireAuth: false,
      isHidden: true,
    },
    signUp: {
      exact: true,
      module: modules.home,
      path: "/signup",
      title: "Create EyeMail account",
      requireAuth: false,
      isHidden: true,
    },
    companySignUp: {
      exact: true,
      module: modules.home,
      path: "/companysignup/:id",
      title: "Create a company account",
      requireAuth: false,
      isHidden: true,
    },
    termsOfService: {
      exact: true,
      module: modules.home,
      path: "/terms-of-service",
      title: "Terms of service",
      requireAuth: false,
      isHidden: false,
      container: true,
    },
    privacyPolicy: {
      exact: true,
      module: modules.home,
      path: "/privacy-policy",
      title: "Privacy policy",
      requireAuth: false,
      isHidden: false,
      container: true,
    },
    myCampaigns: {
      exact: true,
      module: modules.campaign,
      path: "/campaigns",
      title: "My Campaigns",
      isIndex: true,
    },
    myTemplates: {
      exact: true,
      module: modules.template,
      path: "/templates",
      title: "My Templates",
      isIndex: true,
    },
    myCompanies: {
      exact: true,
      module: modules.company,
      path: "/companies",
      title: "My Companies",
      isIndex: true,
    },
    reportsWithId: {
      exact: true,
      module: modules.report,
      route: "/reports",
      path: "/reports/:id",
      title: "View Report",
    },
    reportsWithoutId: {
      exact: true,
      module: modules.report,
      route: "/reports",
      path: "/reports",
      title: "View Report",
      isIndex: true,
    },
    reportsFeed: {
      exact: true,
      module: modules.report,
      route: "/api/reports",
      path: "/api/reports/:id",
      title: "Feed",
    },
    supportCenter: {
      exact: true,
      module: modules.support,
      path: "/support",
      title: "Support Center",
      isIndex: true,
    },
    feedDatas: {
      exact: true,
      module: modules.feeds,
      route: "/feeds",
      path: "/feeds/:id/:type",
      title: "Feed",
      isIndex: true,
    },
  },
};

export default site;
