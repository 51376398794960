import React from 'react';

import './PrivacyPolicy.scss';

const PrivacyPolicy = props => {
  return (
    <div className='page-privacy-policy'>
      <div className='head'>
        <h1>PRIVACY STATEMENT AND COOKIE POLICY</h1>
      </div>
      <div className='body'>
        <p>
          Effective October 5, 2019
        </p>
        <p>
          EyeMail takes data privacy seriously. This privacy policy explains who we are, how we
          collect, share and use Personal Information, and how you can exercise your privacy
          rights.
        </p>
        <p>
          We recommend that you read this privacy policy in full to ensure you are fully informed.
          However, to make it easier for you to review the parts of this privacy policy that apply to
          you, we have divided up the document into sections that are specifically applicable to
          Members  (Section 2), Contacts (Section 3), and Visitors (Section 4). Sections 1 and 5
          are applicable to everyone.
        </p>
        <p>
          If you have any questions or concerns about our use of your Personal Information, then
          please contact us using the contact details provided at the end of Section 5.
        </p>
        <h2>
          1. The Basics
        </h2>
        <h3>
          A. About Us
        </h3>
        <p>
          EyeMail is an online marketing platform (the &quot;Services&quot;) operated by EyeMail Inc., a
          company headquartered in the State of Georgia in the United States (&quot;we,&quot; &quot;us,&quot; &quot;our,&quot;
          and &quot;EyeMail&quot;).
        </p>
        <p>
          The Services enable our Members to, among other things, send and manage email
          campaigns and serve advertisements. We also provide other related services, such as
          real-time data analytics. Find out more about our Services.
        </p>
        <h3>
          B. Key Terms
        </h3>
        <p>
          In this privacy policy, these terms have the following meanings:
          &quot;Contact&quot; is a person a Member may contact through our Services. In other words, a
          Contact is anyone on a Member&#39;s Distribution List or about whom a Member has given
          us information. For example, if you are a Member, a subscriber to your email marketing
          campaigns would be considered a Contact.
        </p>
        <p>
          &quot;Distribution List&quot; is a list of Contacts a Member may upload or manage on our platform
          and all associated information related to those Contacts (for example, email addresses).
          &quot;Member&quot; means any person or entity that is registered with us to use the Services.
        </p>
        <p>
          &quot;Personal Information&quot; means any information that identifies or can be used to identify a
          Member, a Contact, or a Visitor, directly or indirectly. Examples of Personal Information
          include, but are not limited to, first and last name, date of birth, email address, gender,
          occupation, or other demographic information.
        </p>
        <p>
          &quot;Website(s)&quot; means any website(s) we own and operate (such as <a target='_blank' href='https://mailchimp.com/'>www.EyeMail.com</a>) or
          any web pages, interactive features, applications, widgets, blogs, social networks, social
          network &quot;tabs,&quot; or other online, mobile, or wireless offerings that post a link to this
          privacy policy.
        </p>
        <p>
          &quot;Visitor&quot; means any person who visits any of our Websites.
          &quot;you&quot; and &quot;your&quot; means, depending on the context, either a Member, a Contact, or a
          Visitor.
        </p>
        <h2>
          2. Privacy for Members
        </h2>
        <p>
          This section applies to the Personal Information we collect and process from a Member
          or potential Member through the provision of the Services. If you are not a Member,
          the Visitors or Contacts section of this policy may be more applicable to you and your
          data. In this section, &quot;you&quot; and &quot;your&quot; refer to Members and potential Members.
        </p>
        <h3>
          A. Information We Collect
        </h3>
        <p>
          The Personal Information that we may collect broadly falls into the following categories:
        </p>
        <p>
          (i) Information you provide to us: In the course of engaging with our Services, you may
          provide Personal Information about you and your Contacts. Personal Information is
          often, but not exclusively, provided to us when you sign up for and use the Services,
          consult with our customer service team, send us an email, integrate the Services with
          another website or service (for example, when you choose to connect your e-commerce
          account with EyeMail), or communicate with us in any other way.
          We will let you know prior to collection whether the provision of Personal Information we
          are collecting is compulsory or if it may be provided on a voluntary basis and the
          consequences, if any, of not providing the information. By giving us this information, you
          agree to this information being collected, used and disclosed as described in our Terms
          of Use and in this privacy policy.
        </p>
        <p>
          This information may include:
        </p>
        <ul>
          <li>
            Registration information: You need an EyeMail account to use the Services as a
            Member. When you register for an account, you will be asked to provide certain
            basic information, such as your name, email address, username, password,
            company name, occupation, location, and phone number (for example, if you are
            using two-factor authentication).
            </li>
          <li>
            Billing and communications information: If you purchase our Services, you may
            also need to provide us with payment and billing information, such as your credit
            card details and billing address. EyeMail will also maintain a record of your billing
            transactions and purchases, and any communications and responses.
            </li>
          <li>
            Information we process on your behalf: In order to send an email campaign or
            use certain features in your account, you need to upload a Distribution List that
            provides us information about your Contacts, such as their names and email
            addresses. We use and process this information to provide the Services in
            accordance with your instructions.

            </li>
        </ul>
        <p>
          (ii) Information we collect automatically: When you use the Services, we may
          automatically collect certain information about your device and usage of the Services.
          We use cookies and other tracking technologies to collect some of this information. Our
          use of cookies and other tracking technologies is discussed more below, and in more
          detail in our Cookie Statement.
        </p>
        <p>
          This information may include:
        </p>
        <ul>
          <li>
            Device information: We collect information about the device and applications you
            use to access the Services, such as your IP address, your operating system,
            your browser ID, and other information about your system and connection.
            </li>
          <li>
            Log data: Our web servers keep log files that record data each time a device
            accesses those servers and those log files contain data about the nature of each
            access, including originating IP addresses. We may also access metadata and
            other information associated with files that you upload into our Services.
            </li>
          <li>
            Product usage data: We collect usage data about you whenever you interact with
            our Services, which may include the dates and times you access the Services
            and your browsing activities (such as what portions of the Services are used).
            We also collect information regarding the performance of the Services, including
            metrics related to the deliverability of emails and other communications you send
            through the Services. This information allows us to improve the content and
            operation of the Services, and facilitate research and analysis of the Services.
            </li>
        </ul>
        <p>
          (iii) Information we collect from other sources: From time to time, we may obtain
          information about you or your Contacts from third-party sources, such as public
          databases, social media platforms, third-party data providers and our joint marketing
          partners. We take steps to ensure that such third parties are legally or contractually
          permitted to disclose such information to us.
          </p>
        <ul>
          <li>
            Examples of the information we receive from other sources include demographic
            information (such as age and gender), device information (such as IP addresses), location
            (such as city and state), and online behavioral data (such as information about use of
            social media websites, page view information and search results and links). We use this
            information, alone or in combination with other information (including Personal
            Information) we collect, to enhance our ability to provide relevant marketing and content
            to you and to develop and provide you with more relevant products, features, and
            services.
            </li>
        </ul>
        <p>
          (iv) Information from the use of our mobile apps: When you use our mobile apps, we
          may collect certain device and usage-related information in addition to information
          described elsewhere in this privacy policy.
          </p>
        <ul>
          <li>
            Device information: We may collect information about the type of device and
            operating system you use. We do not ask for, access, or track any location-
            based information from your mobile device at any time while downloading or
            using our mobile apps or Services.
            </li>
          <li>
            Product usage data: We may use mobile analytics software to better understand
            how people use our application. We may collect information about how often you
            use the application and other performance data.
            </li>
        </ul>

        <h3>
          B. Use of Personal Information
        </h3>
        <p>
          We may use the Personal Information we collect through the Services or other sources
          for a range of reasons, including:
          </p>
        <ul>
          <li>
            To bill and collect money owed to us by you. This includes sending you emails, invoices,
            receipts, notices of delinquency, and alerting you if we need a different credit card
            number. We use third parties for secure credit card transaction processing, and those third
            parties collect billing information to process your orders and credit card payments. To
            learn more about the steps we take to safeguard that data, see the &quot;Our Security&quot; section
            of this privacy policy.
          </li>
          <li>
            To send you system alert messages. For example, we may inform you about temporary or
            permanent changes to our Services, such as planned outages, or send you account,
            security or compliance notifications, such as new features, version updates, releases,
            abuse warnings, and changes to this privacy policy.
          </li>
          <li>
            To communicate with you about your account and provide customer support. For
            example, if you use our mobile apps, we may ask you if you want to receive push
            notifications about activity in your account. If you have opted in to these push
            notifications and no longer want to receive them, you may turn them off through your
            operating system.
          </li>
          <li>
            To enforce compliance with our Terms of Use and applicable law, and to protect the
            rights and safety of our Members and third parties, as well as our own. This may include
            developing tools and algorithms that help us prevent violations. For example, sometimes
            we review the content of our Members’ email campaigns to make sure they comply with
            our Terms of Use. To improve that process, we have software that helps us find email
            campaigns that may violate our Terms of Use. Our employees or independent contractors
            may review those particular email campaigns. This benefits all Members who comply
            with our Terms of Use because it reduces the amount of spam being sent through our
            servers and helps us maintain high deliverability. Email was not built for confidential
            information. Please do not use EyeMail to send confidential information.
          </li>
          <li>
            To meet legal requirements, including complying with court orders, valid discovery
            requests, valid subpoenas, and other appropriate legal mechanisms.
          </li>
          <li>
            To provide information to representatives and advisors, including attorneys and
            accountants, to help us comply with legal, accounting, or security requirements.
          </li>
          <li>
            To prosecute and defend a court, arbitration, or similar legal proceeding.
          </li>
          <li>
            To respond to lawful requests by public authorities, including to meet national security or
            law enforcement requirements.
          </li>
          <li>
            To provide, support and improve the Services. For example, this may include sharing
            your or your Contacts’ information with third parties in order to provide and support our
            Services or to make certain features of the Services available to you. When we share
            Personal Information with third parties, we take steps to protect your information by
            requiring these third parties to enter into a contract with us that requires them to use the
            Personal Information we transfer to them in a manner that is consistent with this privacy
            policy and applicable privacy laws.
          </li>
          <li>
            To provide suggestions to you. This includes adding features that compare Members’
            email campaigns, using data to suggest other publishers your Contacts may be interested
            in, or using data to suggest products or services that you may be interested in or that may
            be relevant to you or your Contacts. Some of these suggestions are generated by use of
            our data analytics projects, which are described below. For more information about our
            use of cookies and other tracking technologies for this purpose, and instructions about
            how to opt out of having data collected through the use of cookies, please see our Cookie
            Statement.
          </li>
          <li>
            For our data analytics projects. Our data analytics projects use data from EyeMail
            accounts, including Personal Information of Contacts, to provide and improve the
            Services. We use information like your sending habits and your Contacts’ details and
            purchase history, so we can make more informed predictions, decisions, and products for
            our Members. If you or your Contact prefers not to share this data, you can alter the
            security settings on your account to opt out of data analytics projects, or your Contact can
            opt out of data analytics projects at any time by emailing us
            at <a href="mailto: personaldatarequests@EyeMail.com">personaldatarequests@EyeMail.com.</a> For example, we use data from EyeMail accounts
            to enable product recommendation, audience segmentation, and predicted demographics
            features for our Members. We will act as a data controller to process data for our data
            analytics projects in reliance on our legitimate business interests of improving and
            enhancing our products and services for our Members. As always, we take the privacy of
            Personal Information seriously, and will continue to implement appropriate safeguards to
            protect this Personal Information from misuse or unauthorized disclosure.
          </li>
          <li>
            Combined information. We may combine Personal Information with other information
            we collect or obtain about you (such as information we source from our third-party
            partners) to serve you specifically, such as to deliver a product or service according to
            your preferences or restrictions, or for advertising or targeting purposes in accordance
            with this privacy policy. When we combine Personal Information with other information
            in this way, we treat it as, and apply all of the safeguards in this privacy policy applicable
            to, Personal Information.
            </li>
          <li>
            Other purposes. To carry out other legitimate business purposes, as well as other lawful
            purposes about which we will notify you.
            </li>
        </ul>
        <h3>
          C. Cookies and Tracking Technologies
        </h3>
        <p>
          We and our partners may use various technologies to collect and store information
          when you use our Services, and this may include using cookies and similar tracking
          technologies, such as pixels and web beacons. For example, we use web beacons in
          the emails we send on your behalf. These web beacons track certain behavior such as
          whether the email sent through the Services was delivered and opened and whether
          links within the email were clicked. They also allow us to collect information such as the
          recipient’s IP address, browser, email client type and other similar details. We use this
          information to measure the performance of your email campaigns, and to provide
          analytics information and enhance the effectiveness of our Services. Reports are also
          available to us when we send email to you, so we may collect and review that
          information.
        </p>
        <p>
          Our use of cookies and other tracking technologies is discussed in more detail in our
          Cookie Statement.
        </p>
        <h3>
          D. Member Distribution Lists
        </h3>
        <p>
          A Distribution List can be created in a number of ways, including by importing Contacts,
          such as through a CSV or directly from your email client. Your Distribution Lists are
          stored on a secure EyeMail server. We do not, under any circumstances, sell your
          Distribution Lists. If someone on your Distribution List complains or contacts us, we
          might then contact that person. You may export (download) your Distribution Lists from
          EyeMail at any time.
        </p>
        <p>
          If we detect abusive or illegal behavior related to your Distribution List, we may share
          your Distribution List or portions of it with affected ISPs or anti-spam organizations to
          the extent permitted or required by applicable law.
        </p>
        <p>
          If a Contact chooses to use the Forward to a Friend (FTF) link in an email campaign a
          Member sends, it will allow the Contact to share the Member’s email content with
          individuals not on the Member’s Distribution List. When a Contact forwards an email to
          a friend, we do not store the Contact’s email address or their friend’s email address, and
          no one is added to any Distribution List as a result of the FTF link. The Member who
          created the email campaign only sees an aggregate number of times their email
          campaign was forwarded by a Contact and does not have access to the email
          addresses used to share or receive that forwarded content.
        </p>
        <h3>
          E. Other Data Protection Rights
        </h3>
        <p>
          You and your Contacts may have the following data protection rights:
          </p>
        <ul>
          <li>
            To access, correct, update or request deletion of Personal Information. EyeMail takes
            reasonable steps to ensure that the data we collect is reliable for its intended use,
            accurate, complete and up to date. As a Member, you can manage many of your
            individual account and profile settings within the dashboard provided through the
            EyeMail platform, or you may contact us directly by emailing us
            at <a href="mailto: personaldatarequests@EyeMail.com">personaldatarequests@EyeMail.com.</a> You can also manage information about your
            Contacts within the dashboard provided through the EyeMail platform to assist you with
            responding to requests to access, correct, update or delete information that you receive
            from your Contacts.
          </li>
          <li>
            In addition, individuals who are residents of the EEA can object to processing of their
            Personal Information, ask to restrict processing of their Personal Information or request
            portability of their Personal Information. You can exercise these rights by contacting us
            using the contact details provided in the &quot;Questions and Concerns&quot; section below. If any
            of your Contacts wishes to exercise any of these rights, they should contact you directly,
            or contact us as described in the &quot;Privacy for Contacts&quot; section below.
          </li>
          <li>
            Similarly, if Personal Information is collected or processed on the basis of consent, the
            data subject can withdraw their consent at any time. Withdrawing your consent will not
            affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it
            affect processing of your Personal Information conducted in reliance on lawful
            processing grounds other than consent. If you receive these requests from Contacts, you
            can segment your lists within the EyeMail platform to ensure that you only market to
            Contacts who have not opted out of receiving such marketing.
          </li>
          <li>
            The right to complain to a data protection authority about the collection and use of
            Personal Information. For more information, please contact your local data protection
            authority. Contact details for data protection authorities in the EEA are available here.
            We respond to all requests we receive from individuals wishing to exercise their data
            protection rights in accordance with applicable data protection law. We may ask you to
            verify your identity in order to help us respond efficiently to your request. If we receive a
            request from one of your Contacts, we will either direct the Contact to reach out to you,
            or, if appropriate, we may respond directly to their request.
          </li>
        </ul>
        <h2>
          3. Privacy for Contacts
        </h2>
        <p>
          This section applies to the information we process about our Members’ Contacts as a
          data controller pursuant to our legitimate business interests, as explained in the &quot;Legal
          Basis for Processing&quot; section below. Our Services are intended for use by our
          Members. As a result, for much of the Personal Information we collect and process
          about Contacts through the Services, we act as a processor on behalf of our Members.
          EyeMail is not responsible for the privacy or security practices of our Members, which
          may differ from those set forth in this privacy policy. Please check with individual
          Members about the policies they have in place. For purposes of this section, &quot;you&quot; and
          &quot;your&quot; refer to Contacts.
        </p>
        <h3>
          A. Information We Collect
        </h3>
        <p>
          The Personal Information that we may collect or receive about you broadly falls into the
          following categories:
        </p>
        <p>
          (i) Information we receive about Contacts from our Members: A Member may provide
          Personal Information about you to us through the Services. For example, when a
          Member uploads their Distribution List or integrates the Services with another website or
          service (for example, when a Member chooses to connect their e-commerce account
          with EyeMail), or when you sign up for a Member’s Distribution List on a EyeMail signup
          form, they may provide us with certain contact information or other Personal Information
          about you such as your name, email address, address or telephone number. You may
          have the opportunity to update some of this information by electing to update or manage
          your preferences via an email you receive from a Member.
        </p>
        <p>
          (ii) Information we collect automatically: When you interact with an email campaign that
          you receive from a Member or browse or purchase from a Member’s connected store,
          we may collect information about your device and interaction with an email. We use
          cookies and other tracking technologies to collect some of this information. Our use of
          cookies and other tracking technologies is discussed more below, and in more detail in
          our Cookie Statement.
          </p>
        <ul>
          <li>
            Device information: We collect information about the device and applications you use to
            access emails sent through our Services, such as your IP address, your operating system,
            your browser ID, and other information about your system and connection.
            </li>
          <li>
            Product usage data: We collect usage data about you whenever you interact with emails
            sent through the Services, which may include dates and times you access emails and your
            browsing activities (such as what pages are viewed). We also collect information
            regarding the performance of the Services, including metrics related to the deliverability
            of emails and other electronic communications our Members send through the Services.
            This information allows us to improve the content and operation of the Services, and
            facilitate research and analysis of the Services.
            </li>
        </ul>
        <p>
          (iii) Information we collect from other sources: From time to time, we may obtain
          information about you from third-party sources, such as social media platforms and
          third-party data providers. We take steps to ensure that such third parties are legally or
          contractually permitted to disclose such information to us, and we use this information to
          provide publicly available social media information about you to Members who have
          enabled the &quot;Social Profiles&quot; feature in their EyeMail accounts.
        </p>
        <h2>
          B. Use of Personal Information
        </h2>
        <p>
          We may use the Personal Information we collect or receive about you for our legitimate
          business interests, including:
          </p>
        <ul>
          <li>
            To enforce compliance with our Terms of Use and applicable law. This may include
            developing tools and algorithms that help us prevent violations.
            </li>
          <li>
            To protect the rights and safety of our Members and third parties, as well as our own.For
            example, sometimes we review the content of our Members’ email campaigns to make
            sure they comply with our Terms of Use. To improve that process, we have software that
            helps us find email campaigns that may violate our Terms of Use. Our employees or
            independent contractors may review those particular email campaigns, which may
            include your contact information. This reduces the amount of spam being sent through
            our servers and helps us maintain high deliverability.
            </li>
          <li>
            To meet legal requirements, including complying with court orders, valid discovery
            requests, valid subpoenas, and other appropriate legal mechanisms.
            </li>
          <li>
            To provide information to representatives and advisors, including attorneys and
            accountants, to help us comply with legal, accounting, or security requirements.
            </li>
          <li>
            To prosecute and defend a court, arbitration, or similar legal proceeding.
            </li>
          <li>
            To respond to lawful requests by public authorities, including to meet national security or
            law enforcement requirements.
            </li>
          <li>
            To provide, support and improve the Services. For example, this may include sharing
            your information with third parties in order to provide and support our Services or to
            make certain features of the Services available to our Members. When we share Personal
            Information with third parties, we take steps to protect your information by requiring
            these third parties to enter into a contract with us that requires them to use the Personal
            Information we transfer to them in a manner that is consistent with this privacy policy
            and applicable privacy laws.
            </li>
          <li>
            For our data analytics projects. Our data analytics projects use data from EyeMail
            accounts, including your Personal Information, to provide and improve the Services. We
            use information, like your purchase history, provided to us by Members, so we can make
            more informed predictions, decisions, and products for our Members. For example, we
            use data from EyeMail accounts to enable product recommendation, audience
            segmentation, and predicted demographics features for our Members. If you prefer not to
            share this data, you can opt out of data analytics projects at any time by emailing us
              at <a href="mailto: personaldatarequests@EyeMail.com">personaldatarequests@EyeMail.com.</a>
          </li>
          <li>
            Other purposes. To carry out other legitimate business purposes, as well as other lawful
            purposes.
            </li>
        </ul>
        <h3>
          C. Cookies and Tracking Technologies
        </h3>
        <p>
          We and our partners may use various technologies to collect and store information
          when you interact with a Member’s email campaign or connected store, and this may
          include using cookies and similar tracking technologies, such as pixels and web
          beacons. For example, we use web beacons in the emails we send on behalf of our
          Members. When you receive and engage with a Member’s campaign, web beacons
          track certain behavior such as whether the email sent through the EyeMail platform was
          delivered and opened and whether links within the email were clicked. They also allow
          us to collect information such as your IP address, browser, email client type, and other
          similar details. We use this information to measure the performance of our Members’
          email campaigns, and to provide analytics information and enhance the effectiveness of
          our Services.
        </p>
        <p>
          Our use of cookies and other tracking technologies is discussed in more detail in our
          Cookie Statement.
        </p>
        <h3>
          D. Legal Basis for Processing
        </h3>
        <p>
          We process Personal Information about you as a data controller as described in this
          section, where such processing is in our legitimate interests and not overridden by your
          data protection interests or fundamental rights and freedoms. Our legitimate
          interests typically include: improving, maintaining, providing, and enhancing our
          technology, products and services; and ensuring the security of the Services and our
          Website.
        </p>
        <h3>
          E. Other Data Protection Rights
        </h3>
        <p>
          You may have the following data protection rights:
</p>
        <ul>
          <li>
            To access, correct, update or request deletion of your Personal Information. EyeMail
            takes reasonable steps to ensure that the data we collect is reliable for its intended use,
            accurate, complete, and up to date. You may contact us directly at any time about
            accessing, correcting, updating or deleting your Personal Information, or altering your
              data, by submitting a request to us <a href="mailto: request@eyemailinc.com">request@eyemailinc.com.</a> We will consider your
    request in accordance with applicable laws.
            </li>
          <li>
            You have the right to complain to a data protection authority about our collection and use
            of your Personal Information. For more information, please contact your local data
            protection authority. Contact details for data protection authorities in the EEA are
            available.
            </li>
        </ul>
        <p>
          As described above, for much of the Personal Information we collect and process about
          Contacts through the Services, we act as a processor on behalf of our Members. In
          such cases, if you are a Contact and want to exercise any data protection rights that
          may be available to you under applicable law or have questions or concerns about how
          your Personal Information is handled by EyeMail as a processor on behalf of our
          individual Members, you should contact the relevant Member that is using the EyeMail
          Services, and refer to their separate privacy policies.
        </p>
        <p>
          If you no longer want to be contacted by one of our Members through our Services,
          please unsubscribe directly from that Member’s newsletter or contact the Member
          directly to update or delete your data. If you contact us directly, we may remove or
          update your information within a reasonable time and after providing notice to the
          Member of your request.
        </p>
        <p>
          We respond to all requests we receive from individuals wishing to exercise their data
          protection rights in accordance with applicable data protection laws. We may ask you to
          verify your identity in order to help us respond efficiently to your request.
        </p>
        <h2>
          4. Privacy for Visitors
        </h2>
        <p>
          This section applies to Personal Information that we collect and process through our
          Websites and in the usual course of our business, such as in connection with our
          recruitment, events, sales and marketing activities. In this section &quot;you&quot; and &quot;your&quot;
          refers to Visitors.
        </p>
        <h3>
          A. Information We Collect
        </h3>
        <p>
          (i) Information you provide to us on the Websites or otherwise: Certain parts of our
          Websites may ask you to provide Personal Information voluntarily. For example, we
          may ask you to provide certain Personal Information (such as your name, contact
          details, company name, profile information) in order to sign up for a free or paid EyeMail
          account, apply for a role with EyeMail, or otherwise submit inquiries to us. We may also
          collect Personal Information, such as your contact and job details and feedback, when
          you attend our events, take part in surveys, or through other business or marketing
          interactions we may have with you. You may choose to provide additional information
          when you communicate with us or otherwise interact with us, and we will keep copies of
          any such communications for our records.
        </p>
        <p>
          The Personal Information that you are asked to provide, and the reasons why you are
          asked to provide it, will be made clear to you at the point we ask you to provide your
          Personal Information. We will also let you know prior to collection whether the provision
          of the Personal Information we are collecting is compulsory or may be provided on a
          voluntary basis and the consequences, if any, of not providing the information.
        </p>
        <p>
          (ii) Information we collect automatically through the Websites: When you visit our
          Websites, we may also collect certain information automatically from your device. In
          some countries, including countries in the European Economic Area, this information
          may be considered Personal Information under applicable data protection laws.
          </p>
        <p>
          Specifically, the information we collect automatically may include your IP address, your
          operating system, your browser ID, your browsing activity, and other information about
          your system and connection and how you interact with our Websites and other
          websites. We may collect this information as a part of log files as well as through the
          use of cookies or other tracking technologies. Our use of cookies and other tracking
          technologies is discussed more below, and in more detail in our Cookie Statement here.
          </p>
        <h3>
          B. Use of Personal Information
          </h3>
        <p>
          We may use the information we collect through our Websites for a range of reasons,
          including:
          </p>
        <ul>
          <li>
            To provide, operate, optimize, and maintain our Websites.
            </li>
          <li>
            To send you information for marketing purposes, in accordance with your marketing
            preferences.
            </li>
          <li>
            For recruitment purposes if you have applied for a role with EyeMail.
            </li>
          <li>
            To respond to your online inquiries and requests, and to provide you with information
              and access to resources<br /> or services that you have requested from us.
            </li>
          <li>
            To manage our Websites and system administration and security.
            </li>
          <li>
            To improve the navigation and content of our Websites.
            </li>
          <li>
            To identify any server problems or other IT or network issues.
            </li>
          <li>
            To process transactions and to set up online accounts.
            </li>
          <li>
            To compile aggregated statistics about site usage and to better understand the preferences
            of our Visitors.
            </li>
          <li>
            To carry out research and development to improve our products and services.
            </li>
          <li>
            To customize content and layout of the Websites.
            </li>
          <li>
            To carry out other legitimate business purposes, as well as other lawful purposes.
            </li>
        </ul>
        <p>
          In addition, we may combine Personal Information with other information we collect or
          obtain about you (such as information we source from our third-party partners) to serve
          you specifically, such as to deliver a product or service according to your preferences or
          restrictions, or for advertising or targeting purposes in accordance with this privacy
          policy. When we combine Personal Information with other information in this way, we
          treat it as, and apply all of the safeguards in this privacy policy applicable to, Personal
          Information.
        </p>
        <h3>
          C. Public Information and Third-Party Websites
        </h3>
        <ul>
          <li>
            Blog. We have public blogs on our Websites. Any information you include in a comment
            on our blog may be read, collected, and used by anyone. If your Personal Information
            appears on our blogs and you want it removed, contact us. If we are unable to remove
            your information, we will tell you why.
            </li>
          <li>
            Social media platforms and widgets. Our Websites include social media features, such as
            the Facebook Like button. These features may collect information about your IP address
            and which page you are visiting on our Website, and they may set a cookie to make sure
            the feature functions properly. Social media features and widgets are either hosted by a
            third party or hosted directly on our Website. We also maintain presences on social media
            platforms, including Facebook, Twitter, and Instagram. Any information,
            communications, or materials you submit to us via a social media platform is done at
            your own risk without any expectation of privacy. We cannot control the actions of other
            users of these platforms or the actions of the platforms themselves. Your interactions with
            those features and platforms are governed by the privacy policies of the companies that
            provide them.
            </li>
          <li>
            Links to third-party websites. Our Websites include links to other websites, whose
            privacy practices may be different from ours. If you submit Personal Information to any
            of those sites, your information is governed by their privacy policies. We encourage you
            to carefully read the privacy policy of any website you visit.
            </li>
          <li>
            Contests and sweepstakes. We may, from time to time, offer surveys, contests,
            sweepstakes, or other promotions on our Websites or through social media (collectively,
            &quot;Promotions&quot;). Participation in our Promotions is completely voluntary. Information
            requested for entry may include Personal Information such as your name, address, date of
            birth, phone number, email address, username, and similar details. We use the
            information you provide to administer our Promotions. We may also, unless prohibited
            by the Promotion’s rules or law, use the information provided to communicate with you,
            or other people you select, about our Services. We may share this information with our
            affiliates and other organizations or service providers in line with this privacy policy and
            the rules posted for our Promotions.
            </li>
        </ul>
        <h3>
          D. Cookies and Tracking Technologies
        </h3>
        <p>
          We use cookies and similar tracking technologies to collect and use Personal
          Information about you, includiFng to serve interest-based advertising. For further
          information about the types of cookies and tracking technologies we use, why, and how
          you can control them, please see our Cookie Statement.
        </p>
        <h3>
          E. Other Data Protection Rights
        </h3>
        <p>
          You have the following data protection rights:
          </p>
        <ul>
          <li>
            To access, correct, update, or request deletion of your Personal Information. EyeMail
            takes reasonable steps to ensure that the data we collect is reliable for its intended use,
            accurate, complete, and up to date. You may contact us directly at any time about
            accessing, correcting, updating, or deleting your Personal Information, or altering your
            data or marketing preferences by emailing us at <a href="mailto: personaldatarequests@EyeMail.com">personaldatarequests@EyeMail.com.</a> We
            will consider your request in accordance with applicable laws.
            </li>
          <li>
            In addition, if you are a resident of the EEA, you can object to processing of your
            Personal Information, ask us to restrict processing of your Personal Information
            or request portability of your Personal Information. Again, you can exercise these rights
              by emailing us at <a href="mailto: personaldatarequests@EyeMail.com">personaldatarequests@EyeMail.com.</a>
          </li>
          <li>
            Similarly, if we have collected and processed your Personal Information with your
            consent, then you can withdraw your consent at any time. Withdrawing your consent will
            not affect the lawfulness of any processing we conducted prior to your withdrawal, nor
            will it affect processing of your Personal Information conducted in reliance on lawful
            processing grounds other than consent.
            </li>
          <li>
            You have the right to complain to a data protection authority about our collection and use
            of your Personal Information. For more information, please contact your local data
            protection authority. Contact details for data protection authorities in the EEA are
            available.
            </li>
        </ul>
        <p>
          We respond to all requests we receive from individuals wishing to exercise their data
          protection rights in accordance with applicable data protection laws. We may ask you to
          verify your identity in order to help us respond efficiently to your request.
        </p>
        <h2>
          5. General Information
        </h2>
        <h3>
          A. How We Share Information
        </h3>
        <p>
          We may share and disclose your Personal Information to the following types of third
          parties for the purposes described in this privacy policy (for purposes of this section,
          &quot;you&quot; and &quot;your&quot; refer to Members, Contacts, and Visitors unless otherwise indicated):
        </p>
        <p>
          (i) Our service providers: Sometimes, we share your information with our third-party
          service providers, who help us provide and support our Services and other business-
          related functions.
        </p>
        <p>
          For example, if it is necessary to provide Members something they have requested (like
          enable a feature such as Social Profiles), then we may share Members’ or Contacts’
          Personal Information with a service provider for that purpose. Other examples include
          analyzing data, hosting data, engaging technical support for our Services, processing
          payments, and delivering content.
        </p>
        <p>
          These third-party service providers enter into a contract that requires them to use your
          Personal Information only for the provision of services to us and in a manner that is
          consistent with this privacy policy.
        </p>
        <p>
          In connection with our Services, we use a third-party service provider, Twilio, Inc. We
          use Twilio&#39;s API, which allows us to build features into our EyeMail application to enable
          us to communicate with our Members through texting and calling, and their &quot;Authy&quot;
          product, which we use for two-factor authentication for our application. If you are a
          Member, Twilio may need to collect and process certain Personal Information about you
          as a controller to provide such services. To learn more about Twilio&#39;s privacy practices,
          please visit <a target='_blank' href='https://www.twilio.com/legal/privacy'>https://www.twilio.com/legal/privacy.</a>
        </p>
        <p>
          (ii) Advertising partners: We may partner with third-party advertising networks and
          exchanges to display advertising on our Websites or to manage and serve our
          advertising on other sites and may share Personal Information of Members and Visitors
          with them for this purpose. We do not sell or market to any of our Members’ Contacts.
          We and our third-party partners may use cookies and other tracking technologies, such
          as pixels and web beacons, to gather information about your activities on our Websites
          and other sites in order to provide you with targeted advertising based on your browsing
          activities and interests. For more information about cookies and other tracking
          technologies, please see our Cookie Statement.
        </p>
        <p>
          (iii) Any competent law enforcement body, regulatory body, government agency, court
          or other third party where we believe disclosure is necessary (a) as a matter of
          applicable law or regulation, (b) to exercise, establish, or defend our legal rights, or (c)
          to protect your vital interests or those of any other person.
        </p>
        <p>
          (iv) A potential buyer (and its agents and advisors) in the case of a sale, merger,
          consolidation, liquidation, reorganization, or acquisition. In that event, any acquirer will
          be subject to our obligations under this privacy policy, including your rights to access
          and choice. We will notify you of the change either by sending you an email or posting a
          notice on our Website.
        </p>
        <p>
          (v) Any other person with your consent.
        </p>
        <h2>
          B. Legal Basis for Processing Personal Information (EEA Persons Only)
        </h2>
        <p>
          If you are from the European Economic Area, our legal basis for collecting and using the
          Personal Information described above will depend on the Personal Information
          concerned and the specific context in which we collect it.
        </p>
        <p>
          However, we will normally collect and use Personal Information from you where the
          processing is in our legitimate interests and not overridden by your data-protection
          interests or fundamental rights and freedoms. Typically, our legitimate interests include
          improving, maintaining, providing, and enhancing our technology, products, and
          services; ensuring the security of the Services and our Websites; and for our marketing
          activities.
        </p>
        <p>
          If you are a Member, we may need the Personal Information to perform a contract with
          you. In some limited cases, we may also have a legal obligation to collect Personal
          Information from you.
        </p>
        <p>
          If we ask you to provide Personal Information to comply with a legal requirement or to
          perform a contact with you, we will make this clear at the relevant time and advise you
          whether the provision of your Personal Information is mandatory or not, as well as of the
          possible consequences if you do not provide your Personal Information.

        </p>
        <p>
          Where required by law, we will collect Personal Information only where we have
          your consent to do so.
        </p>
        <p>
          If you have questions about or need further information concerning the legal basis on
          which we collect and use your Personal Information, please contact us using the contact
          details provided in the &quot;Questions and Concerns&quot; section below.
        </p>
        <h3>
          C. Your Choices and Opt-Outs
        </h3>
        <p>
          Members and Visitors who have opted into our marketing emails can opt out of
          receiving marketing emails from us at any time by clicking the &quot;unsubscribe&quot; link at the
          bottom of our marketing messages.
        </p>
        <p>
          Also, all opt-out requests can be made by emailing us using the contact details provided
          in the &quot;Questions and Concerns&quot; section below. Please note that some communications
          (such as service messages, account notifications, billing information) are considered
          transactional and necessary for account management, and Members cannot opt out of
          these messages unless you cancel your EyeMail account.
        </p>
        <h3>
          D. Our Security
        </h3>
        <p>
          We take appropriate and reasonable technical and organizational measures to protect
          Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and
          destruction, taking into account the risks involved in the processing and the nature of
          the Personal Information. For further information about our security practices, please
          see our Security page. If you have any questions about the security of your Personal
          Information, you may contact us at <a href="mailto: privacy@EyeMail.com">privacy@EyeMail.com.</a>
        </p>
        <p>
          EyeMail accounts require a username and password to log in. Members must keep their
          username and password secure, and never disclose it to a third party. Because the
          information in a Member’s EyeMail account is so sensitive, account passwords are
          hashed, which means we cannot see a Member’s password. We cannot resend
          forgotten passwords either. We will only provide Members with instructions on how to
          reset them.
        </p>
        <h3>
          E. International Transfers
        </h3>
        <p>
          (i) We operate in the United States
        </p>
        <p>
          Our servers and offices are located in the United States, so your information may be
          transferred to, stored, or processed in the United States. While the data protection,
          privacy, and other laws of the United States might not be as comprehensive as those in
          your country, we take many steps to protect your privacy, including offering our
          Members a Data Processing Agreement.
        </p>
        <p>
          (ii) Data transfers from Switzerland or the EU to the United States
        </p>
        <p>
          EyeMail participates in and has certified its compliance with the EU-U.S. Privacy Shield
          Framework and the Swiss-U.S. Privacy Shield Framework. We are committed to
          subjecting all Personal Information received from European Union (EU) member
          countries and Switzerland, respectively, in reliance on each Privacy Shield Framework,
          to each Framework’s applicable Principles. To learn more about the Privacy Shield
          Frameworks, and to view our certification, visit the U.S. Department of Commerce’s
          Privacy Shield website.
        </p>
        <p>
          A list of Privacy Shield participants is maintained by the Department of Commerce and
          is available.
        </p>
        <p>
          EyeMail is responsible for the processing of Personal Information we receive under
          each Privacy Shield Framework and subsequently transfer to a third party acting as an
          agent on our behalf. We comply with the Privacy Shield Principles for all onward
          transfers of Personal Information from the EU and Switzerland, including the onward
          transfer liability provisions.
        </p>
        <p>
          With respect to Personal Information received or transferred pursuant to the Privacy
          Shield Frameworks, we are subject to the regulatory enforcement powers of the U.S.
          Federal Trade Commission. In certain situations, we may be required to disclose
          Personal Information in response to lawful requests by public authorities, including to
          meet national security or law enforcement requirements.
        </p>
        <p>
          If you have an unresolved privacy or data use concern that we have not addressed
          satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free
          of charge to you) at <a target='_blank' href='https://feedback-form.truste.com/watchdog/request'>https://feedback-form.truste.com/watchdog/request</a>.
          Under certain conditions, more fully described on the Privacy Shield website,<a target='_blank' href='https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint'> here</a>, you may be
          entitled to invoke binding arbitration when other dispute resolution procedures have
          been exhausted.
        </p>
        <h3>
          F. Retention of Data
        </h3>
        <p>
          We retain Personal Information where we have an ongoing legitimate business or legal
          need to do so. Our retention periods will vary depending on the type of data involved,
          but, generally, we&#39;ll refer to these criteria in order to determine retention period:
          </p>
        <ul>
          <li>
            Whether we have a legal or contractual need to retain the data.
            </li>
          <li>
            Whether the data is necessary to provide our Services.
            </li>
          <li>
            Whether our Members have the ability to access and delete the data within their EyeMail
            accounts.
            </li>
          <li>
            Whether our Members would reasonably expect that we would retain the data until they
            remove it or until their EyeMail accounts are closed or terminated.
            </li>
        </ul>
        <p>
          When we have no ongoing legitimate business need to process your Personal
          Information, we will either delete or anonymize it or, if this is not possible (for example,
          because your Personal Information has been stored in backup archives), then we will
          securely store your Personal Information and isolate it from any further processing until
          deletion is possible.
        </p>
        <h3>
          G. California Privacy
        </h3>
        <p>
          Under California Law, California residents have the right to request in writing from
          businesses with whom they have an established business relationship, (a) a list of the
          categories of Personal Information, such as name, email, and mailing address, and the
          type of services provided to the customer that a business has disclosed to third parties
          (including affiliates that are separate legal entities) during the immediately preceding
          calendar year for the third-parties’ direct marketing purposes, and (b) the names and
          addresses of all such third parties. To request the above information, please contact us
          through hello@eyemailinc.com or email us at <a href="mailto: privacy@EyeMail.com">privacy@EyeMail.com</a>.
        </p>
        <h3>
          H. Changes to this Policy
        </h3>
        <p>
          We may change this privacy policy at any time and from time to time. The most recent
          version of the privacy policy is reflected by the version date located at the top of this
          privacy policy. All updates and amendments are effective immediately upon notice,
          which we may give by any means, including, but not limited to, by posting a revised
          version of this privacy policy or other notice on the Websites. We encourage you to
          review this privacy policy often to stay informed of changes that may affect you. Our
          electronically or otherwise properly stored copies of this privacy policy are each deemed
          to be the true, complete, valid, authentic, and enforceable copy of the version of this
          privacy policy that was in effect on each respective date you visited the Website.
        </p>
        <h3>
          I. Questions &amp; Concerns
        </h3>
        <p>
          If you have any questions or comments, or if you have a concern about the way in
          which we have handled any privacy matter, please use our email to send us a message
          at questions@eyemailinc.com. You may also contact us by postal mail or email at:
        </p>
      </div>
    </div >
  );
};

export { PrivacyPolicy };
